<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>

    <el-form :model="form" :rules="rules" ref="form" style="width:650px">
      <el-form-item label="面板名称" prop="name" label-width="130px">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="所属品类" prop="category_code" label-width="130px">
        <el-select v-model="form.category_code">
            <el-option
              v-for="item in categrylist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="专属型号" prop="product_model_code" label-width="130px">
        <el-input v-model="form.product_model_code" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item v-if="edit" label="安卓文件 key" label-width="130px">
        <el-input disabled v-model="form.android_file" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="安卓文件" label-width="130px">
        <el-upload
          class="upload-demo"
          :action="previews3url"
          :show-file-list="false"
          :http-request="fileUpload"
          :on-change="handleChange"
        >
          <el-button size="small" type="primary">{{androidfile}}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="edit" label="IOS文件key" label-width="130px">
        <el-input disabled v-model="form.ios_file" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="ios文件" label-width="130px">
        <el-upload
          class="upload-demo"
          :action="previews3url1"
          :show-file-list="false"
          :http-request="fileUpload"
          :on-change="handleChange1"
        >
          <el-button size="small" type="primary">{{iosfile}}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="面板缩略图" label-width="130px">
        <el-upload
          class="avatar-uploader"
          :action="previews3url2"
          :show-file-list="false"
          :http-request="fileUpload"
          @change="handleChange2"
          :auto-upload="false"
        >
          <img v-if="previewUrl" :src="previewUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="130px">
        <el-button type="primary" size="small" @click="Validate(Confirm)">提交</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      form: {
        code: '',
        name: '',
        category_code: '',
        android_file: '',
        ios_file: '',
        img_url: '',
        product_model_code: '',
      },
      rules: {
        name: [{ required: true, message: '请输入面板名称', trigger: 'blur' }],
        category_code: [{ required: true, message: '请选择所属品类', trigger: 'blur' }],
        android_file: [{ required: true, message: '请选择安卓文件', trigger: 'blur' }],
        ios_file: [{ required: true, message: '请选择ios文件', trigger: 'blur' }],
        img_url: [{ required: true, message: '请选择面板缩略图', trigger: 'blur' }]
      },
      edit: false,
      categrylist: [],
      androidfile: '选择文件',
      iosfile: '选择文件',
      previews3url: '',
      previews3url1: '',
      previews3url2: '',
      previewUrl: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetInfo () {
      this.api.PanelDetail({
        code: this.$route.params.code
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.api.S3down({
            key: res.data.data.img_url
          }).then(res => {
            this.previewUrl = res.data.data.get_url
          })
          this.api.S3down({
            key: res.data.data.android_file
          }).then(res => {
            console.log('安卓文件',res.data.data.get_url)
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetCategory () {
      this.api.CategoryList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.categrylist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    // 文件上传
    handleChange (event) {
      this.GetMd5('android',event.raw)
    },
    handleChange1 (event) {
      this.GetMd5('ios',event.raw)
    },
    handleChange2 (event) {
      this.GetMd5('img',event.raw)
    },
    GetMd5(type,file) {
      console.log(file,file.type,'文件类型')
      util.getFileMD5(file, md5 => {
        let params = {
          key_action: 'category_panel',
          key_md5: md5,
          key_type: type=='img'?'thumb':'display',
          key_suffix: file.name.split('.')[1],
          content_length: file.size,
          content_type: file.type?file.type:file.name.split('.')[1]
        }
        this.GetPreSign(params,file,type)
      })
    },
    GetPreSign (params,file,type) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          if (type == 'android') {
            this.form.android_file = res.data.data.key
            this.androidfile = '修改文件'
            this.fileUpload(file,res.data.data.put_url)
          } else if (type == 'ios') {
            this.iosfile = '修改文件'
            this.form.ios_file = res.data.data.key
            this.fileUpload(file,res.data.data.put_url)
          } else if (type == 'img') {
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
            this.previewUrl = res.data.data.get_url
            this.form.img_url = res.data.data.key
            this.previews3url2 = res.data.data.put_url
            this.fileUpload(file,res.data.data.put_url) 
          }
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    fileUpload (file,urls) {
      let config = {
        headers: {
          'Content-Type': file.type?file.type:'html',
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        }
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      console.log('源地址',urls)
      let url = '/aws' + urls.split(urlReg.exec(urls)[0])[1]
      console.log('切割地址',url)
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
        }
      }).catch( err => {
        console.log(err)
        this.$message.error('上传失败:'+err)
      })
    },
    Confirm () {
      this.api.PanelUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm('提交成功，是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    goback () {
      this.$router.push('/iotmanage/panel')
    }
  },
  filters: {},
  mounted () {
    if (this.$route.params.code == 0) {
      this.edit = false
    } else {
      this.form.code = this.$route.params.code
      this.edit = true
      this.iosfile = "修改文件"
      this.androidfile = "修改文件"
      this.GetInfo()
    }
  },
  created () {
    this.GetCategory()
  }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
